import React from "react";
import { Link } from "react-router-dom";
import FooterLink from "../components/Footer";
import Title from "../components/Title";
import CV from "../assets/img/cv.pdf";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pruthvish Vaghela</title>
<meta name="description" content="Hi, I'm Pruthvish Vaghela passionate Full Stack developer." />

<meta property="og:type" content="website" />
<meta property="og:site_name" content="Pruthvish" />
<meta property="og:title" content="Pruthvish Vaghela" />
<meta property="og:url" content={window.location.href} />
<meta property="og:image" content={window.location.href + '/img/image.jpg'} />
<meta property="og:description" content="Hi, I'm Pruthvish Vaghela passionate Full Stack developer." />

<meta itemprop="name" content="Pruthvish Vaghela" />
<meta itemprop="url" content={window.location.href} />
<meta itemprop="thumbnailUrl" content={window.location.href + '/img/image.jpg'} />
<meta itemprop="image" content={window.location.href + '/img/image.jpg'} />

<meta name="twitter:title" content="Pruthvish Vaghela" />
<meta name="twitter:image" content={window.location.href + '/img/image.jpg'} />
<meta name="twitter:url" content={window.location.href} />
<meta name="twitter:card" content="summary" />
<meta name="twitter:creator" content="Pruthvish" />
<meta name="twitter:description" content="Hi, I'm Pruthvish Vaghela passionate Full Stack developer." />
      </Helmet>

      <div className="top__link text-sm">
        <Link to="/about-me">About</Link>
      </div>
      <main>
        <div className="about">
          <div className="top__link text-sm">
            <Link to="/" className="link">
              Home
            </Link>
          </div>

          <div className="about__infos container">
            <Title
              lineContent={
                <div
                  className="about__photo"
                  style={{
                    backgroundImage: "url('/img/image.jpg')",
                    width: 300,
                    height: 450,
                    marginBottom: 100,
                    marginRight: 50,
                  }}
                ></div>
              }
              lineContent2={<> </>}
            />
            <Title
              lineContent={
                <div className="about__perso">
                  <div className="about__contact">
                    <p style={{ fontSize: 25 }}>
                      <>Pruthvish Vaghela</>
                    </p>
                    <p>
                      <>I'm a passionate Full Stack developer</>
                    </p>
                    <br></br>
                    <span>Contact</span>
                    <address>
                      <a href="mailto:pruthvish.dev@gmail.com">
                        pruthvish.dev@gmail.com
                      </a>
                      <br></br> <a href="tel:+918160721563">+91 8160721563</a>
                    </address>
                  </div>
                  <div className="about__links">
                    {" "}
                    <a
                      href="https://www.instagram.com/_pruth_20/"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    <br></br>{" "}
                    <a
                      href="https://www.linkedin.com/in/pruthvish-vaghela/"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Linkedin
                    </a>
                    <br></br>{" "}
                    <a
                      href="https://github.com/pruthvish98"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GitHub
                    </a>
                    <br></br>{" "}
                     {/* <a
                       href="#"
                       className="link"
                       target="_blank"
                       rel="noreferrer"
                     >
                       My Projects
                     </a> */}
                    <br></br>{" "}
                  </div>{" "}
                  <a
                    href={CV}
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download CV
                  </a>
                  <div className="about__scroll"></div>
                </div>
              }
              lineContent2={<> </>}
            />
          </div>
          <div className="about__description container">
            <p className="projects__link">
              Software Engineer with 4+ year of onboard experience in javascript,
              php with willingness to learn and practice
            </p>
            <p className="projects__link">
              React.Js, Express.Js, Node.Js .Strongest area of expertise
              Laravel, API Integration, custom script devlopment, Database
              design expert.
            </p>
            <p className="projects__link">
               I'm very passionate about makeing new things & experiments. 
               apart from that i loved to capture moments in weekends
               or in free time i make cinematic reels and capture the photographs.
            </p>
          </div>
        </div>
      </main>
      <FooterLink />
      <div className="screen__transition">
        <div className="screen__white"></div>
        <div className="screen__black"></div>
      </div>
    </>
  );
}
