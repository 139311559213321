import React from "react";

export default function FooterLink() {
  const footerLinks = [
    {
      href: "https://www.instagram.com/_pruth_20/reels/",
      title: "Instagram",
    },
    {
      href: "https://www.linkedin.com/in/pruthvish-vaghela/",
      title: "Linkedin",
    },
    {
      href: "https://github.com/pruthvish98",
      title: "GitHub",
    },
    {
      href: "mailto:pruthvish.dev@gmail.com",
      title: "Email",
    },
  ];
  return (
    <footer className="footer container text-sm">
      {footerLinks.map((val, i) => {
        return (
          <div
            className={
              val.title === "GitHub" ? "footer__link github" : "footer__link"
            }
            key={i}
          >
            <a
              href={val.href}
              className="link"
              rel="noreferrer"
              target="_blank"
              rel="noreferrer"
              key={i}
            >
              {val.title}
            </a>
          </div>
        );
      })}
    </footer>
  );
}
