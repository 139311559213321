import React from "react";
import { Link } from "react-router-dom";
import FooterLink from "../components/Footer";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
        <link rel="canonical" href="https://pruthvish.in/" />
        <link rel="description" href="Hi, I'm Pruthvish Vaghela" />
      </Helmet>
      <div className="top__link text-sm">
        <Link to="/about-me" className="link">
          About
        </Link>
      </div>
      <main>
        <div>
          <nav className="projects text-big">
            <p className="projects__link" style={{}}>
              {" "}
              Under Construction{" "}
            </p>
          </nav>
        </div>
      </main>
      <FooterLink />
      <div className="screen__transition">
        <div className="screen__white"></div>
        <div className="screen__black"></div>
      </div>
    </>
  );
}
