import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Home, About } from "./pages/index";
import { CSSTransition } from "react-transition-group";
import Particles from "react-particles-js";
import Cursor from "./components/Cursor";
import "./App.scss";

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/about-me", name: "About", Component: About },
  // { path: "/contact", name: "Contact", Component: Contact },
  // { path: "/portfolio", name: "Portfolio", Component: Portfolio },
  // { path: "/resume", name: "Resume", Component: Resume },
];

const particals = {
  particles: {
    shape: {
      type: "circle",
      stroke: {
        width: 0,
        color: "#f1f2f4",
      },
      polygon: {
        nb_sides: 5,
      },
    },
    opacity: {
      value: 0.5,
      random: !1,
      anim: {
        enable: !1,
        speed: 1,
        opacity_min: 0.1,
        sync: !1,
      },
    },
    size: {
      value: 0,
      random: !0,
      anim: {
        enable: !1,
        speed: 40,
        size_min: 0.1,
        sync: !1,
      },
    },
    line_linked: {
      enable: !0,
      distance: 150,
      opacity: 0.75,
      width: 1,
    },
    move: {
      enable: !0,
      speed: 2,
      direction: "none",
      random: !1,
      straight: !1,
      out_mode: "out",
      bounce: !1,
      attract: {
        enable: !1,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: !0,
        mode: "grab",
      },
      onclick: {
        enable: !0,
        mode: "push",
      },
      resize: !0,
    },
    modes: {
      grab: {
        distance: 140,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: !0,
};

function App() {
  const onEnter = (node) => {
    // gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
    // staggerReveal(reveal1, reveal2);
  };

  const onExit = (node) => {
    // staggerRevealClose(reveal2, reveal1);
    //Allow menu to have height of 100%
    // gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
    // gsap.to([reveal1, reveal2], {
    //   duration: 0,
    //   opacity: 1,
    //   height: "100%",
    // });
    // staggerReveal(reveal1, reveal2);
    // fadeInUp(info);
    // staggerText(line1, line2, line3);
  };

  return (
    <Router>
      <Particles
        params={particals}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
      <Switch>
        {routes.map(({ path, Component }) => (
          <Route key={path} path={path} exact>
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={1200}
                classNames="page"
                onExit={onExit}
                onEntering={onEnter}
                unmountOnExit
              >
                <div className="page">
                  <Component />
                </div>
              </CSSTransition>
            )}
          </Route>
        ))}
      </Switch>
    </Router>
  );
}

export default App;
